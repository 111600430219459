*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  
}
body {
  background-color:gray !important;
}

.search-results {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    max-height: calc(100vh - 350px); 
    overflow-y: auto; 
    border-radius: 4px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    background-color: #fff;
    border-radius: 4px;
  }

  .multiple-epos-search-results {
    width: 100%;
    max-height: calc(100vh - 350px); 
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    background-color: #fff;
    border-radius: 4px;
  }

  .multiple-epos-item{
    padding: 16px;
    border-radius: 4px;
  }

  .search-results-item:hover, .multiple-epos-item:hover{
    background-color: #efefef;
    border-radius: 4px;
  }

  .search-results-item {
    cursor: pointer;
    /* margin-bottom: 14px; */
    padding: 16px;
    border-radius: 4px;
  }

  .search-results-item div{
    margin-bottom: 5px;
  }
  
  .search-results-item:last-child {
    margin-bottom: 0;
  }
  
  .divider {
    height: .5px;
    width: 100%;
    background-color: #ccc;
  }
  
  .item-input-container {
    position: relative;
  }
  
  .input-with-results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }

  .file-input {
    display: none;
  }
  
  .file-input-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
  }
  